import React, { FC } from 'react'

import { LocaleSelector } from '@juullabs/react-components'
import { FlattenedEntry, Footer as FooterType } from '@viewlio/types/src/contentful'
import cx from 'classnames'

import { useGlobalStore } from 'stores'
import { useFromOauthCookie } from 'utils/hooks'

import styles from './Footer.module.scss'
import { getProps } from './Footer.serializer'

type Props = {
  contentfulEntry: FlattenedEntry<FooterType>
  showLocaleSelectorOnDesktop?: boolean
}

export const Footer: FC<Props> = ({
  contentfulEntry,
  showLocaleSelectorOnDesktop = false,
}) => {
  const {
    locale,
    juulioStore: {
      availableLocales,
      flagUrl,
      preferences: {
        showBottomNav,
      },
    },
  } = useGlobalStore()

  const { isFromOauth } = useFromOauthCookie()

  if (isFromOauth) {
    return null
  }

  const { leftColumns, rightHandSideContent } = getProps(contentfulEntry)

  return (
    <div
      className={cx(styles.container, {
        [styles.withBottomNav]: showBottomNav,
      })}
      data-testid='main-footer'
    >
      <div className={styles.content}>
        {leftColumns?.length > 0 && (
          <div className={cx(styles.column, styles.leftColumn)}>
            {leftColumns.map((column, index) => (
              <div
                key={`footer-column-${index}`}
                dangerouslySetInnerHTML={{ __html: column }}
              />
            ))}
          </div>
        )}

        <div
          className={cx(styles.column, styles.rightColumn)}
          dangerouslySetInnerHTML={{ __html: rightHandSideContent }}
        />
      </div>

      {availableLocales.length > 1 && (
        <LocaleSelector
          current={locale}
          currentBase={locale?.split('-')[0]}
          currentImage={flagUrl}
          display={true}
          locales={availableLocales}
          className={cx('content-wrapper store-locale-selector--footer', {
            'show-on-desktop': showLocaleSelectorOnDesktop,
          })}
          placement='footer'
        />
      )}
    </div>
  )
}
