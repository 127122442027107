import { Media } from '@viewlio/types/src'

export const getAspectRatio = (image: Media) => {
  if (!image?.details?.image?.height
    || !image?.details?.image?.width) {
    return 0
  }

  const { details: { image: size } } = image
  return size.height / size.width
}
