import {
  boolean as yupBoolean,
  object as yupObject,
  ref as yupRef,
  string as yupString,
} from 'yup'

import { passwordSchema } from 'utils/validationSchema/password'

export const validationSchema = (
  storeCode: string,
) => yupObject().shape({
  burdensomeClauses: yupBoolean().oneOf(
    [storeCode === 'juul-it'],
    { id: 'sign_up.form.checkbox_error_message' },
  ),
  email: yupString()
    .required({ id: 'common.validations.required' })
    .email({ id: 'common.validations.email' }),
  password: passwordSchema,
  passwordConfirmation: passwordSchema
    .oneOf([yupRef('password'), null], { id: 'common.validations.passwords_match' }),
  termsAndConditions: yupBoolean().oneOf([true], { id: 'sign_up.form.checkbox_error_message' }),
})

