import React from 'react'

import { addTargetBlankToAnchor } from 'utils/formatters'
import { useTranslations } from 'utils/translations/useTranslations'

type Props = {
  className?: string
  id: string
  textComponent?: 'div' | 'p' | 'span'
  values?: Record<string, any>
  withTargetBlankAnchors?: boolean
}

export const TranslatedMessageWithHtml: React.FC<Props> = ({
  id,
  values,
  textComponent,
  className,
  withTargetBlankAnchors = false,
}) => {
  const { translateMessage } = useTranslations()
  const baseInnerHtml = translateMessage(id, values)
  const finalInnerHtml = withTargetBlankAnchors ?
    addTargetBlankToAnchor(true, baseInnerHtml) : baseInnerHtml

  return React.createElement(textComponent || 'span', {
    className,
    dangerouslySetInnerHTML: { __html: finalInnerHtml },
  })
}
