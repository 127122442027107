import React, { FC, useState } from 'react';
import classNames from 'classnames';

import { Icon } from '@viewlio/juulio-bridge/src/common/components/Icon';

type OptionProps = React.HTMLProps<HTMLOptionElement> & {
  title: string;
};

type Props = React.HTMLProps<HTMLSelectElement> & {
  label?: React.ReactNode;
  name: string;
  onChange?(event: React.ChangeEvent<HTMLSelectElement>): void;
  options: OptionProps[];
  value: string;
  error?: boolean;
  errorText?: string;
  wrapperClass?: string;
  selectInputClass?: string;
};

export const SelectInput: FC<Props> = ({
  label,
  name,
  onBlur,
  onFocus,
  options,
  value,
  error,
  errorText,
  wrapperClass,
  selectInputClass,
  ...otherSelectProps
}) => {
  const [hasFocus, setFocus] = useState(false);

  const handleBlur = e => {
    setFocus(false);
    onBlur?.(e);
  };

  const handleFocus = e => {
    setFocus(true);
    onFocus?.(e);
  };

  const selectedOption = options.find(option => {
    return option.value.toString() === value?.toString?.();
  });

  const labelClassNames = classNames('select-input__label', {
    'select-input__label--float': !!selectedOption,
    'subheading--mini': !!selectedOption,
    'subheading--small': !selectedOption,
  });

  const selectInputClassNames = classNames('select-input', selectInputClass, {
    'select-input--disabled':
      otherSelectProps.disabled || otherSelectProps.readOnly,
    'select-input--error': error || !!errorText,
    'select-input--focus': hasFocus,
    'select-input--with-float': !!selectedOption && !!label,
  });

  const id = otherSelectProps.id || `select-input-${name}`;

  return (
    <div className={wrapperClass}>
      <div className={selectInputClassNames}>
        {label && (
          <label className={labelClassNames} htmlFor={id}>
            {label}
          </label>
        )}

        {selectedOption?.title && (
          <span className="select-input__text">{selectedOption?.title}</span>
        )}

        <select
          className="select-input__element"
          id={id}
          name={name}
          onBlur={handleBlur}
          onFocus={handleFocus}
          value={selectedOption?.value}
          {...otherSelectProps}
        >
          {options.map((option, i) => (
            <option key={i} {...option}>
              {option.title}
            </option>
          ))}
        </select>

        <Icon className="select-input__icon" name="chevron-down" />
      </div>

      {!!errorText && (
        <span className="select-input__error toggle-input__error">
          {errorText}
        </span>
      )}
    </div>
  );
};
