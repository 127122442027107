import React from 'react'

import classNames from 'classnames'

type Props = {
  alt?: string
  className?: string
  name: string
  path?: string
} & React.ImgHTMLAttributes<HTMLImageElement>

export const Icon: React.FC<Props> = ({
  alt,
  className,
  name,
  path = 'icons_v2',
  ...props
}) => (
  <img
    alt={alt}
    className={classNames(className, 'icon')}
    src={`/images/${path}/${name}.svg`}
    {...props}
  />
)

