import { MarketingPageEntry, LegalPageEntry } from '@viewlio/types/src/contentful'

import { formatEntry } from 'lib/contentful/formatEntry'

export const layoutSections = [
  'collection_section',
  'image_content_section',
  'page_hero',
  'personalized_entry',
]

const recursivelyApplyAttribute = (section, attribute, value) => {
  section[attribute] = value

  if (section.items) {
    section.items.forEach((item) =>
      recursivelyApplyAttribute(item, attribute, value),
    )
  }

  return section
}

export const getProps = (entry: MarketingPageEntry | LegalPageEntry) => {
  let formattedEntry

  if (entry?.sys.contentType.sys.id === 'marketing_page') {
    formattedEntry = formatEntry(entry as MarketingPageEntry)
  } else if (entry?.sys.contentType.sys.id === 'legal_page' || entry?.sys.contentType.sys.id === 'business_page') {
    formattedEntry = formatEntry(entry as LegalPageEntry)
  } else {
    return null
  }

  const { sections, ...page } = formattedEntry

  let aboveTheFoldSections = 2

  return {
    ...page,
    sections: sections.map((section) => {
      const aboveTheFold =
        layoutSections.includes(section.contentType) && aboveTheFoldSections > 0

      if (aboveTheFold) {
        aboveTheFoldSections -= 1
      }

      return recursivelyApplyAttribute(section, 'aboveTheFold', aboveTheFold)
    }),
  }

}
