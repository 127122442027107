import React, { useState } from 'react'

import { Button, TextInput } from '@juullabs/react-components'
import { viewlioConfig } from '@viewlio/config/src/viewlioConfig'
import { paths } from '@viewlio/config/src/viewlioConfig/paths'
import { JuulStoreStoreNewsletter } from '@viewlio/types/src'
import { Formik, Form as FormikForm, FormikProps, FormikBag } from 'formik'
import cookie from 'js-cookie'
import { useRouter } from 'next/router'

import { TranslatedMessage } from 'components/common/TranslatedMessage'
import { TranslatedMessageWithHtml } from 'components/common/TranslatedMessageWithHtml'
import { signUp } from 'lib/juulio/api'
import { useGlobalStore } from 'stores'
import { addFlashMessage, flushFlashMessages } from 'stores/uiStore'
import { camelizeKeys } from 'utils/caseConversion'
import { getTranslatedError } from 'utils/formik/getTranslatedError'
import { useFromOauthCookie } from 'utils/hooks'
import { useSessionStorageFlashMessages } from 'utils/hooks/useSessionStorageFlashMessages'

import styles from './SignUpForm.module.scss'
import { validationSchema } from './SignUpForm.schema'
import { SignUpFormCheckboxes } from './SignUpFormCheckboxes'

type Values = {
  burdensomeClauses: boolean
  email: string
  juulNewsletter: boolean
  password: string
  passwordConfirmation: string
  selectAll: boolean
  termsAndConditions: boolean
}

const buildInitialValues = () => ({
  burdensomeClauses: false,
  email: '',
  juulNewsletter: Boolean(
    cookie.get(viewlioConfig.cookies.juul2ComplexFlavorsEarlyAccess),
  ),
  password: '',
  passwordConfirmation: '',
  selectAll: false,
  termsAndConditions: false,
})

export type TranslatedMessageErrorProps = {
  id: string
  values?: Record<string, unknown>
}

const prepareParams =
  (values: Values, storeNewsletter: JuulStoreStoreNewsletter) => {
    const checkboxValue = (value: boolean) => (value ? '1' : '0')

    const newsletterValue = (value: boolean) => {
      const {
        isMarketingAllowed,
        isOptinRequired,
      } = storeNewsletter

      const UNSUBSCRIBED_VALUE = '0'
      const SUBSCRIBED_VALUE = '1'

      if (!isMarketingAllowed) return UNSUBSCRIBED_VALUE
      if (!isOptinRequired) return SUBSCRIBED_VALUE
      return value ? SUBSCRIBED_VALUE : UNSUBSCRIBED_VALUE
    }

    return {
      agreements: {
        burdensomeClauses: checkboxValue(values.burdensomeClauses),
        privacyPolicy: checkboxValue(values.termsAndConditions),
        termsAndConditions: checkboxValue(values.termsAndConditions),
      },
      spreeUser: {
        email: values.email,
        juulNewsletter: newsletterValue(values.juulNewsletter),
        password: values.password,
        passwordConfirmation: values.passwordConfirmation,
      },
    }
  }

export const SignUpForm: React.FC = () => {
  const { juulioStore } = useGlobalStore()
  const {
    code: storeCode,
    storeNewsletter,
  } = juulioStore

  const router = useRouter()
  const redirect = (router?.query?.redirect as string) || null

  const { setSessionFlashMessage } = useSessionStorageFlashMessages()

  const { isFromOauth } = useFromOauthCookie()

  const initialValues = buildInitialValues()

  const [showPassword, setShowPassword] = useState(false)
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false)

  const handleIconClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ): void => {
    e.preventDefault()
    e.currentTarget.getAttribute('aria-label') === 'password' ?
      setShowPassword(prev => !prev)
      : setShowPasswordConfirm(prev => !prev)
  }

  const onSubmit = async (
    values: Values,
    formikBag: FormikBag<FormikProps<Values>, Values>,
  ) => {
    const { setSubmitting } = formikBag
    setSubmitting(true)

    const params = prepareParams(values, storeNewsletter)
    try {
      const response = await signUp(params, redirect)
      const { redirectUrl, welcomeMessage } = response

      if (redirectUrl) {
        if (welcomeMessage) {
          setSessionFlashMessage(welcomeMessage)
        }

        window.location.assign(redirectUrl)
        return
      }

      // Expected juulio errors here are 409 conflict (a user is currently
      // authed) and 422 (an account already exists). These api responses
      // are thrown without being unwrapped in src/lib/juulio > fetchFromApi
      // so we unwrap them here. It is likely to be refactored in juulio
      // to always return 2XX with/without an 'error' field in the future
      const errBody = camelizeKeys(await response.json())
      throw Error(errBody.error)
    } catch (err) {
      flushFlashMessages()
      addFlashMessage({
        content: err.message,
        type: 'error',
      })
      setSubmitting(false)
    }
  }

  return (
    <>
      <div className={styles.ageVerification}>
        <TranslatedMessage id='sign_up.start_age_verification' />
      </div>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema(storeCode)}
      >
        {(props: FormikProps<Values>) => {
          const { dirty, isValid } = props
          return (
            <FormikForm>
              <TextInput
                name='email'
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                labelText={<TranslatedMessage id='sign_up.form.placeholder_email' />}
                errorText={getTranslatedError(props.getFieldMeta('email'))}
                value={props.values.email || ''}
                wrapperClass={styles.textInput}
              />

              <TextInput
                autoComplete='new-password'
                name='password'
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                labelText={<TranslatedMessage id='sign_up.form.placeholder_password' />}
                errorText={getTranslatedError(props.getFieldMeta('password'))}
                value={props.values.password || ''}
                type={showPassword ? 'text' : 'password'}
                icon='eye_outlined'
                iconAriaLabel='password'
                onIconClick={handleIconClick}
                wrapperClass={styles.textInput}
              />

              <TextInput
                name='passwordConfirmation'
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                labelText={<TranslatedMessage id='sign_up.form.placeholder_password_confirmation' />}
                errorText={getTranslatedError(props.getFieldMeta('passwordConfirmation'))}
                value={props.values.passwordConfirmation || ''}
                type={showPasswordConfirm ? 'text' : 'password'}
                icon='eye_outlined'
                iconAriaLabel='passwordConfirmation'
                onIconClick={handleIconClick}
                wrapperClass={styles.textInput}
              />

              <SignUpFormCheckboxes />

              <Button
                data-testid='signupButton'
                disabled={!dirty || !isValid || props.isSubmitting}
                processing={props.isSubmitting}
              >
                <TranslatedMessage id='sign_up.form.header' />
              </Button>

              <TranslatedMessageWithHtml
                textComponent='div'
                id='sign_up.account_recovery'
                className={styles.oneAccountOnly}
                values={{ path:
                  isFromOauth ? paths.resetPassword : paths.accountRecovery }}
              />
            </FormikForm>
          )
        }}
      </Formik>
    </>
  )
}
