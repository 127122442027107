import { NavigationEntry } from '@viewlio/types/src/contentful'

import { formatEntry } from 'lib/contentful/formatEntry'

type SigninItemType = {
  displayText: string
  id: string
  image: {
    url: string
  }
  link: string
}

const parseSignInItems = (entry): SigninItemType[] => entry.signinItems?.map(
  ({ displayText, id, image, link }) => ({
    displayText,
    id,
    image: {
      url: image?.url,
    },
    link,
  }),
) ?? []

export const getParsedItems = (entry: NavigationEntry) => {
  const formattedEntry = formatEntry(entry)

  return {
    signInItems: parseSignInItems(formattedEntry),
  }
}
