import React, { useEffect, useState } from 'react'

import { viewlioConfig } from '@viewlio/config/src/viewlioConfig'
import { DeviseFailure } from '@viewlio/types/src'
import { ImageSection, MarketingPageEntry, NavigationEntry } from '@viewlio/types/src/contentful'
import { FlashMessageTypes } from '@viewlio/types/src/FlashMessage'
import cx from 'classnames'
import { Entry as EntryType } from 'contentful'
import cookie from 'js-cookie'
import { useRouter } from 'next/router'

import { HeaderMinimal, HeaderSignIn } from 'components/common/Headers'
import { SplitLayoutWithLogo } from 'components/common/SplitLayoutWithLogo'
import { TranslatedMessage } from 'components/common/TranslatedMessage'
import { Entry } from 'components/contentful/Entry'
import { getProps } from 'components/contentful/utils/pageSectionsSerializer/pageSections.serializer'
import { useGlobalStore } from 'stores'
import { addFlashMessage } from 'stores/uiStore'
import { withStoreCustomization } from 'utils/hocs/withStoreCustomization'
import { useFromOauthCookie } from 'utils/hooks'

import { SignInForm } from '../SignInForm'
import { SignUpForm } from '../SignUpForm'

import styles from './SignIn.module.scss'

enum Tabs {
  SignIn = 'sign-in',
  SignUp = 'sign-up'
}

type BodyProps = {
  backdropImageSectionEntry?: EntryType<ImageSection>
}

const UsSignInBody: React.FC<BodyProps> = ({
  backdropImageSectionEntry,
}) => {
  const { isFromOauth } = useFromOauthCookie()

  return (
    <SplitLayoutWithLogo
      backdropImageSectionEntry={backdropImageSectionEntry}
      centerContent={true}
      contentBodyIsWhite={false}
      contentBody={<FormTabs />}
      hideBrandLogoOnMobile={true}
      minHeightLogoBodyInPxOnDesktop={720}
      minHeightInVh={0}
      noMinHeightOnTablet={!isFromOauth}
      smallMissionFontOnMobile={true}
      wrapperClass={isFromOauth && styles.wrapperClass}
    />
  )
}

const DefaultSignInBody: React.FC<BodyProps> = () => {
  const { isFromOauth } = useFromOauthCookie()

  return (
    <SplitLayoutWithLogo
      centerContent={true}
      contentBodyIsWhite={false}
      contentBody={<FormTabs/>}
      hideBrandLogoOnMobile={true}
      minHeightLogoBodyInPxOnDesktop={720}
      minHeightInVh={0}
      noMinHeightOnTablet={!isFromOauth}
      smallMissionFontOnMobile={true}
      wrapperClass={isFromOauth && styles.wrapperClass}
    />
  )
}

const SignInBody = withStoreCustomization({
  'juul-us': UsSignInBody,
}, DefaultSignInBody)

const FormTabs = () => {
  const {
    isFromOauth,
    isOauthSignUpRequest,
    isRegisterAccountTabHidden,
  } = useFromOauthCookie()

  const [tab, setTab] = useState<Tabs>(null)

  useEffect(() => {
    if (isFromOauth) {
      if (isOauthSignUpRequest && !isRegisterAccountTabHidden) {
        setTab(Tabs.SignUp)
      } else {
        setTab(Tabs.SignIn)
      }
    } else {
      if (cookie.get(viewlioConfig.cookies.juulioUserGuid)) {
        setTab(Tabs.SignIn)
      } else {
        setTab(Tabs.SignUp)
      }
    }
  }, [
    isFromOauth,
    isOauthSignUpRequest,
    isRegisterAccountTabHidden,
  ])

  const Form = tab === Tabs.SignIn ? SignInForm : SignUpForm

  return (
    <>
      <div className={styles.formTabsWrapper}>
        <div className={styles.card}>
          <div className={styles.tabs}>
            {!isRegisterAccountTabHidden && (
              <button
                onClick={() => setTab(Tabs.SignUp)}
                className={cx(styles.tab, {
                  [styles.active]: tab === Tabs.SignUp,
                })}
              >
                <TranslatedMessage id='sign_in.new_account.link'/>
              </button>
            )}
            <button
              onClick={() => setTab(Tabs.SignIn)}
              className={cx(styles.tab, {
                [styles.active]: tab === Tabs.SignIn,
                [styles.static]: isRegisterAccountTabHidden,
              })}
            >
              <TranslatedMessage id='sign_in.form.header'/>
            </button>
          </div>
          <div>
            <Form/>
          </div>
        </div>
      </div>
    </>
  )
}

type Props = BodyProps & {
  contentfulEntry?: MarketingPageEntry
  navigationEntry: NavigationEntry
}

export const SignIn: React.FC<Props> = ({
  backdropImageSectionEntry,
  contentfulEntry,
  navigationEntry,
}) => {
  const { juulioStore } = useGlobalStore()
  const { isBehindSignIn } = juulioStore

  const { isFromOauth } = useFromOauthCookie()

  const marketingPageProps = getProps(contentfulEntry)

  const router = useRouter()
  const failure = router.query?.failure as DeviseFailure

  // Use a query argument `failure` to render
  // a flash message with a Devise failure message.
  useEffect(() => {
    const valid = Object
      .values(DeviseFailure)
      .includes(failure)

    if (valid) {
      addFlashMessage({
        translationId: `devise.failure.${failure}`,
        type: 'error',
      })
    }
  }, [failure])

  // Use cookie(s) to render a Devise or Doorkeeper
  // flash notification.
  useEffect(() => {
    const flashCookieKeys = ['doorkeeper_flash']

    if (!isBehindSignIn) {
      flashCookieKeys.push('devise_flash')
    }

    flashCookieKeys.forEach((key) => {
      const flashCookie =
        cookie.get(key) ? JSON.parse(decodeURIComponent(cookie.get(key)).replace(/\+/g, ' ')) : {}

      cookie.remove(key)

      Object.keys(flashCookie).map((key) => {
        // translate from juulio flash keys to viewlio flash keys
        const keyTranslations = {
          alert: 'error',
        }
        const flashType = key in keyTranslations ? keyTranslations[key] : 'success'

        addFlashMessage({
          content: flashCookie[key],
          timeout: 30_000,
          type: flashType as FlashMessageTypes,
        })
      })
    })

  }, [])

  return (
    <>
      {
        isFromOauth ?
          <HeaderMinimal navigationEntry={navigationEntry}/>
          : <HeaderSignIn navigationEntry={navigationEntry}/>
      }
      {marketingPageProps?.headerItems?.map(item => (
        <Entry
          key={item.id}
          contentType={item.contentType}
          {...item}
        />
      ))}
      <SignInBody backdropImageSectionEntry={backdropImageSectionEntry} />
    </>
  )
}
