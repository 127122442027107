export const addTargetBlankToAnchor = (
  shouldAddTargetBlank: boolean,
  stringWithHtml: string,
) => {
  if (!shouldAddTargetBlank) {
    return stringWithHtml
  }

  return stringWithHtml.replace(/href/g, 'target="_blank" href')
}
