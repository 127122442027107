import React, { ReactNode } from 'react'

import { Icon } from '@viewlio/juulio-bridge/src/common/components/Icon'
import { Media } from '@viewlio/types/src'
import { ImageSection } from '@viewlio/types/src/contentful'
import cx from 'classnames'
import { Entry } from 'contentful'

import { formatEntry } from 'lib/contentful/formatEntry'
import { useGlobalStore } from 'stores'
import { useTranslations } from 'utils/translations/useTranslations'

import { NextGenImage } from '../NextGenImage'

import styles from './SplitLayoutWithLogo.module.scss'
export type MinHeightInVh = 100 | 80 | 0

type Props = {
  backdropImageSectionEntry?: Entry<ImageSection>
  centerContent?: boolean
  contentBody: ReactNode
  contentBodyIsWhite?: boolean
  footer?: ReactNode
  hideBrandLogoOnMobile?: boolean
  logoBodyIsWhite?: boolean
  minHeightInVh?: MinHeightInVh
  minHeightLogoBodyInPxOnDesktop?: number
  noMinHeightOnTablet?: boolean
  smallMissionFontOnMobile?: boolean
  wrapperClass?: string
}

export const SplitLayoutWithLogo: React.FC<Props> = ({
  logoBodyIsWhite = false,
  backdropImageSectionEntry = null,
  centerContent = false,
  contentBody,
  contentBodyIsWhite = true,
  footer = null,
  hideBrandLogoOnMobile = false,
  minHeightInVh = 100,
  minHeightLogoBodyInPxOnDesktop = null,
  noMinHeightOnTablet = false,
  smallMissionFontOnMobile = false,
  wrapperClass,
}) => {
  const { translateMessage } = useTranslations()
  const wrapperClasses =
    cx(
      styles.wrapper,
      // these classes should operate in an either/or fashion as wrapperClass
      // should only be required to control height in special circumstances
      // (e.g. when we need to use css calc to make scroll overflow less likely)
      wrapperClass || {
        [styles.wrapperMinHeight100Vh]: minHeightInVh === 100,
        [styles.wrapperMinHeight80Vh]: minHeightInVh === 80,
        [styles.wrapperMinHeight0Vh]: minHeightInVh === 0,
        [styles.noMinHeightOnTablet]: noMinHeightOnTablet,
      },
      { [styles.centeredContent]: centerContent },
    )

  const logoBodyClasses =
    cx(
      styles.body,
      styles.logoBody,
      {
        [styles.isWhite]: logoBodyIsWhite,
        [styles.smallFontMobile]: smallMissionFontOnMobile,
        [styles.minHeight720pxDesktop]: minHeightLogoBodyInPxOnDesktop === 720,
      },
    )

  const contentBodyClasses =
    cx(
      styles.body,
      styles.contentBody,
      { [styles.isWhite]: contentBodyIsWhite },
    )

  const {
    juulioStore: {
      ageGate: {
        header: {
          mission,
        },
      },
    },
  } = useGlobalStore()

  const formattedImageEntry = formatEntry(backdropImageSectionEntry)

  return (
    <div className={wrapperClasses} data-testid='SplitLayoutWithLogo-wrapper'>
      <div className={logoBodyClasses} data-testid='SplitLayoutWithLogo-logoBody'>
        <div className={styles.container}>
          {formattedImageEntry && (
            <NextGenImage
              altText={translateMessage('devices')}
              desktopImage={formattedImageEntry.desktopImage as Media}
              mobileImage={formattedImageEntry.desktopImage as Media}
              maxWidths={{
                desktop: 1216,
                mobile: 767,
              }}
              imageClass={styles.backdropImg}
            />
          )}
          <div className={cx(styles.logoBodyContainer)}>
            <Icon
              className={cx(styles.logo, {
                [styles.hiddenOnMobile]: hideBrandLogoOnMobile,
              })}
              path='icons/logos'
              name='brand-black'
              data-testid='brand-logo'
            />
            {mission && (
              <div className={styles.mission}
                dangerouslySetInnerHTML={{
                  __html: translateMessage('age_gate.components.regular.mission_text'),
                }}>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className={contentBodyClasses} data-testid='SplitLayoutWithLogo-contentBody'>
        <div className={styles.container}>
          <div className={styles.contentBodyContainer} data-testid='SplitLayoutWithLogo-contentBodyContainer'>
            {contentBody}
          </div>
        </div>
      </div>

      {footer && (
        <div className={styles.footer}>
          {footer}
        </div>
      )}
    </div>
  )
}
