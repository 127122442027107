import React, { FC } from 'react'

import { HeaderSecondary } from '@juullabs/react-components'
import { NavigationEntry } from '@viewlio/types/src/contentful'

import { LocalizedLink } from 'components/common/LocalizedLink'
import { Subheader } from 'components/common/Subheader'

import { Logo } from '../Logo'

import styles from './HeaderSignIn.module.scss'
import { getParsedItems } from './HeaderSignIn.serializer'

type Props = {
  navigationEntry: NavigationEntry
}

export const HeaderSignIn: FC<Props> = ({
  navigationEntry,
}) => {
  const { signInItems } = getParsedItems(navigationEntry)

  return (
    <div data-testid='header-signin'>
      <HeaderSecondary
        classes={{
          content: styles.content,
          contentWrapper: styles.contentWrapper,
        }}
        startSlot={<Logo navigationEntry={navigationEntry}/>}
        endSlot={Boolean(signInItems.length) && (
          <div className={styles.desktopNavItems}>
            {signInItems.map(item => (
              <LocalizedLink
                key={item.id}
                href={item.link}
                className={styles.desktopNavItem}
              >
                {item.image.url && (
                  // eslint-disable-next-line @next/next/no-img-element
                  <img
                    src={item.image.url}
                    alt={item.displayText}
                    className={styles.desktopNavItemImage}
                  />
                )}
                <div className={styles.desktopNavItemLabel}>
                  {item.displayText}
                </div>
              </LocalizedLink>
            ))}
          </div>
        )}
      />
      <Subheader />
    </div>
  )
}

