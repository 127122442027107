import React, { FC } from 'react'

import { TranslatedMessageWithHtml } from 'components/common/TranslatedMessageWithHtml'
import { withStoreCustomization } from 'utils/hocs/withStoreCustomization'

import styles from '../SignInForm.module.scss'

import { Default } from './Default'
import { Italy } from './Italy'

type TranslatedMessageWithHtmlProps =
  React.ComponentProps<typeof TranslatedMessageWithHtml>

export const displayText = ({
  id,
  values = {},
  isFromOauth = false,
}: {
  id: TranslatedMessageWithHtmlProps['id']
  isFromOauth?: boolean
  values?: TranslatedMessageWithHtmlProps['values']
}) => (
  <TranslatedMessageWithHtml
    className={styles.consentWrapperSection}
    id={id}
    values={values}
    textComponent='div'
    withTargetBlankAnchors={isFromOauth}
  />
)

const SignInConsentForStore = withStoreCustomization({
  'juul-it': Italy,
}, Default)

export const SignInConsent: FC = () => (
  <div className={styles.consentWrapper}>
    <SignInConsentForStore />
  </div>
)
