import React, { FC } from 'react'

import { MarketingPageEntry } from '@viewlio/types/src/contentful'

import { Entry } from 'components/contentful/Entry'
import { getProps } from 'components/contentful/utils/pageSectionsSerializer/pageSections.serializer'

type Props = {
  contentfulEntry: MarketingPageEntry
}

export const Landing: FC<Props> = ({ contentfulEntry }) => {
  const { sections } = getProps(contentfulEntry)

  return sections.map((section) => (
    <Entry
      key={section.id}
      contentType={section.contentType}
      {...section}
    />
  ))
}
