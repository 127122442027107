import { object as yupObject, string as yupString, mixed as yupMixed } from 'yup'

const MIN_PASSWORD_LENGTH = 6

export const validationSchema = (showStatesSelector) =>
  yupObject().shape({
    email: yupString()
      .email({ id: 'common.validations.email' })
      .required({ id: 'common.validations.required' }),
    password: yupString()
      .min(
        MIN_PASSWORD_LENGTH,
        { id: 'common.validations.min_length', values: { length: MIN_PASSWORD_LENGTH } },
      )
      .required({ id: 'common.validations.required' }),
    state: showStatesSelector ? yupObject({
      label: yupString(),
      value: yupString()
        .required({ id: 'age_gate.components.regular.state_age_gate.submit_error.state_missing' },
        ),
    }) : yupMixed().notRequired(),
  })
