import React, { FC } from 'react'

import { SelectInput } from '@viewlio/juulio-bridge/src/common/components/Form/SelectInput'
import { SelectedState, StateListItem, StatesList } from '@viewlio/types/src/AgeGate'

import { useTranslations } from 'utils/translations/useTranslations'

import styles from './StateSelector.module.scss'

type Props = {
  callback(state: StateListItem): void
  isCanada?: boolean
  isLoading?: boolean
  selectedState?: SelectedState
  selector: StatesList
}

export const StateSelector: FC<Props> = ({
  isCanada = false,
  isLoading = false,
  selector,
  selectedState,
  callback,
}) => {
  const { translateMessage } = useTranslations()
  const rawSelect = states => {
    const options = states.map(({ value, label }) => ({ title: label, value }))

    if (isCanada) {
      options.forEach(state => {
        state.title = translateMessage(
          `age_gate.components.states.ca_${state.value.toLowerCase()}`,
        )
      })
    }

    options.unshift({
      title: translateMessage('sign_in.state_selector.default'),
      value: '',
    })

    return (
      <div className={styles.wrapper}>
        <SelectInput
          id='signin-states'
          name='signin-states'
          disabled={isLoading}
          onChange={e => {
            callback({
              label: e.target.options[e.target.selectedIndex].text,
              value: e.target.value,
            })
          }}
          options={options}
          value={selectedState?.value}
        />
      </div>
    )
  }
  return (
    <React.Fragment>
      {rawSelect(selector.states)}
    </React.Fragment>
  )
}
