export enum DeviseFailure {
  AlreadyAuthenticated = 'already_authenticated',
  Inactive = 'inactive',
  Invalid = 'invalid',
  InvalidToken = 'invalid_token',
  Locked = 'locked',
  ManagedByOkta = 'managed_by_okta',
  Timeout = 'timeout',
  Unauthenticated = 'unauthenticated',
  Unconfirmed = 'unconfirmed'
}
