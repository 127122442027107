import React from 'react'

import { Media } from '@viewlio/types/src'

import { createUrl } from '.'
import { QUALITY_SETTING } from './constants'

type SourceProps = {
  image: Media
  imageWidth: number
  maxWidth?: number
  minWidth?: number
}

export const Source: React.FC<SourceProps> = ({
  image,
  imageWidth,
  minWidth,
  maxWidth,
}) => {
  const mediaQuery = (maxWidth ? `(max-width: ${maxWidth}px)` : '')
    + (maxWidth && minWidth ? ' and ' : '')
    + (minWidth ? `(min-width: ${minWidth}px)` : '')
  return (
    <>
      {image.contentType !== 'image/svg+xml' && (
        <source
          type='image/webp'
          media={mediaQuery}
          srcSet={createUrl(image, imageWidth * QUALITY_SETTING, 'image/webp')}
        />
      )}
      <source
        type={image.contentType}
        media={mediaQuery}
        srcSet={createUrl(image, imageWidth * QUALITY_SETTING)}
      />
    </>
  )
}
