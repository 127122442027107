import { FooterProps } from '@viewlio/juulio-bridge/src/common/components/Footer/Footer'
import { FlattenedEntry, Footer } from '@viewlio/types/src/contentful'

const parseLeftColumns = ({
  supportContent,
  ourCompanyContent,
  otherContent,
}): Array<string> => ([
  supportContent,
  ourCompanyContent,
  otherContent,
].filter((col) => col !== undefined))

export const getProps = (
  footerEntry: FlattenedEntry<Footer>,
): FooterProps => {
  const { rightHandSideContent } = footerEntry

  return {
    leftColumns: parseLeftColumns(footerEntry),
    rightHandSideContent: rightHandSideContent,
  }
}
