import { FC } from 'react'

import { useGlobalStore } from 'stores'
import { useFromOauthCookie } from 'utils/hooks'

import { displayText } from './SignInConsent'

export const Default: FC = () => {
  const { juulioStore: {
    tosUrl,
    privacyPolicyUrl,
  } } = useGlobalStore()

  const { isFromOauth } = useFromOauthCookie()

  return displayText({
    id: 'sign_in.login.by_logging_in_b2c',
    isFromOauth,
    values: {
      privacy_url: privacyPolicyUrl,
      tos_url: tosUrl,
      url: tosUrl,
    },
  })
}
