type ImageParams = Record<string, string>

export const addParams = (url: string, params: ImageParams) => {
  const correctedUrl = url.startsWith('//') ? `https:${url}` : url
  const newUrl = new URL(correctedUrl)

  for (const param in params) {
    newUrl.searchParams.set(param, params[param])
  }

  return newUrl.toString()
}
