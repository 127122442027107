import React, { ReactNode } from 'react'

import { TranslatedMessage } from 'components/common/TranslatedMessage'

export const getTranslatedError = (
  fieldMeta,
): ReactNode | undefined => {
  const { error, touched } = fieldMeta
  if (touched && error?.id) {
    return <TranslatedMessage {...error} />
  }
}
