import React from 'react'

import { LocaleSelector } from '@juullabs/react-components'

import { useGlobalStore } from 'stores'
import { storeLocalesSelector } from 'stores/globalStore.selectors'

import styles from './LanguageSelector.module.scss'

export const LanguageSelector = () => {
  const storeLocales = useGlobalStore(storeLocalesSelector)
  return (
    <div className={styles.languageSelector}>
      {storeLocales?.display && (
        <div className={styles.buttonWrapper} data-testid='nav-LocaleSelector'>
          <LocaleSelector
            {...storeLocales}
            currentImage=''
          />
        </div>
      )}
    </div>
  )
}
