import React from 'react'

import { Media } from '@viewlio/types/src'

import { SRC_INCREMENT_AMOUNT } from './constants'
import { Source } from './Source'

type ImageSourcesProps = {
  image: Media
  isDesktop: boolean
  maxWidth: number
  minWidth: number
}

export const ImageSources: React.FC<ImageSourcesProps> = ({
  image,
  isDesktop,
  maxWidth,
  minWidth,
}) => {
  if (image.contentType === 'image/svg+xml') {
    // For SVGs we don't need multiple sources with different sizes
    return (
      <Source
        image={image}
        imageWidth={maxWidth}
        // For desktop we want to skip max-width
        maxWidth={isDesktop ? null : maxWidth}
        // For mobile we want to skip min-width
        minWidth={!isDesktop ? null : minWidth}
      />
    )
  }

  const sources = []
  //the max width decreases constantly by SRC_INCREMENT_AMOUNT
  for (
    let currentMax = maxWidth;
    currentMax >= minWidth;
    currentMax -= SRC_INCREMENT_AMOUNT
  ) {
    // The min width on each step, is the next max width + 1
    let currentMin = currentMax - SRC_INCREMENT_AMOUNT + 1
    // Except for the last step, when we need to ensure the min width for
    // this step is not less than the general min width
    currentMin = currentMin < minWidth ? minWidth : currentMin

    sources.push(
      <Source
        key={`${image.url}-desktop-${currentMax}`}
        image={image}
        imageWidth={currentMax}
        // For desktop we want the biggest size to skip max-width
        maxWidth={isDesktop && currentMax === maxWidth ? null : currentMax}
        // For mobile we want the smaller size to skip min-width
        minWidth={!isDesktop && currentMin === minWidth ? null : currentMin}
      />,
    )
  }

  return (<>{sources}</>)
}
