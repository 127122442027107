import { string as yupString } from 'yup'

const MIN_PASSWORD_LENGTH = 10
const PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).+$/

export const passwordSchema = yupString()
  .required({ id: 'common.validations.required' })
  .nullable()
  .min(
    MIN_PASSWORD_LENGTH,
    { id: 'common.validations.min_length', values: { length: MIN_PASSWORD_LENGTH } },
  )
  .matches(
    PASSWORD_REGEX,
    { message: { id: 'common.validations.password_requirement' } },
  )
