import React, { useEffect, useState } from 'react'

import { Button, TextInput } from '@juullabs/react-components'
import { paths } from '@viewlio/config/src/viewlioConfig/paths'
import { Formik, FormikProps, Form as FormikForm, FormikBag, useFormikContext } from 'formik'
import { useRouter } from 'next/router'

import { LocalizedLink } from 'components/common/LocalizedLink'
import { TranslatedMessage } from 'components/common/TranslatedMessage'
import { BlockedStateWarningMessage } from 'components/signIn/components/BlockedStateWarningMessage'
import { StateSelector } from 'components/signIn/components/StateSelector'
import { signIn } from 'lib/juulio/api'
import { useGlobalStore } from 'stores'
import { addFlashMessage, flushFlashMessages } from 'stores/uiStore'
import { camelizeKeys } from 'utils/caseConversion'
import { useFromOauthCookie } from 'utils/hooks'
import { useSessionStorageFlashMessages } from 'utils/hooks/useSessionStorageFlashMessages'

import { SignInConsent } from './SignInConsent'
import styles from './SignInForm.module.scss'
import { validationSchema } from './SignInForm.schema'

type State = {
  label: string
  value: string
}

type Values = {
  email: string
  password: string
  state?: State
}

export type TranslatedMessageErrorProps = {
  id: string
  values?: Record<string, unknown>
}

const getError = (
  formik,
  fieldName: string,
): TranslatedMessageErrorProps | null => {
  const { touched, error } = formik.getFieldMeta(fieldName)
  return touched && error ? error : null
}

export const SignInForm = () => {
  const {
    juulioStore: {
      ageGate: {
        body: {
          selector,
          blockedStates,
        },
      },
      code,
      preferences: {
        showStateSelectorSignin,
      },
    },
    shopper: {
      geoLocation, meta: { loaded },
    },
  } = useGlobalStore()

  const { setSessionFlashMessage } = useSessionStorageFlashMessages()

  const router = useRouter()
  const redirect = (router?.query?.redirect as string) || null

  const { isFromOauth } = useFromOauthCookie()

  const showStatesSelector =
    showStateSelectorSignin && selector.states

  const StateSelectorGeolocation = () => {
    const { setFieldValue, values } = useFormikContext<Values>()

    useEffect(() => {
      if (loaded && geoLocation) {
        const geolocatedStateFromDropdown =
          selector.states.find(e => e.value === geoLocation.stateAbbr)

        // Populate state form field with geolocated value if such option exists
        // and no option is yet selected by user
        if (geolocatedStateFromDropdown && values.state.value === '') {
          setFieldValue(
            'state',
            geolocatedStateFromDropdown,
          )
        }
      }
    }, [geoLocation, loaded])

    return null
  }

  const initialValues = {
    email: '',
    password: '',
    state: {
      label: '',
      value: '',
    },
  }

  const onSubmit = async (
    values: Values,
    formikBag: FormikBag<FormikProps<Values>, Values>,
  ) => {
    flushFlashMessages()
    const { setSubmitting } = formikBag
    setSubmitting(true)
    try {
      if (!showStatesSelector) {
        values.state = undefined
      }
      // Success in this api-request returns 302, unsuccessful auth comes
      // back as 422. Both responses are thrown without being unwrapped
      // in src/lib/juulio > fetchFromApi so we unwrap them here. It is likely
      // to be refactored in juulio to always return 2XX with/without an 'error'
      // field in the future
      const response = await signIn(values, redirect)
      const body = await response.json()
      throw camelizeKeys(body)
    } catch (body) {
      const { error, redirectUrl, welcomeMessage } = body
      if (error) {
        addFlashMessage({
          content: error,
          type: 'error',
        })
        setSubmitting(false)
      }

      if (redirectUrl) {
        if (welcomeMessage) {
          setSessionFlashMessage(welcomeMessage)
        }
        window.location.assign(redirectUrl)
      }
    }
  }

  const [showPassword, setShowPassword] = useState(false)

  const handleIconClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ): void => {
    e.preventDefault()
    setShowPassword(prev => !prev)
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema(showStatesSelector)}
    >
      {
        (props: FormikProps<Values>) => {
          const {
            dirty,
            isValid,
            handleChange,
            handleBlur,
            values,
            isSubmitting,
          } = props
          return (
            <>
              <FormikForm>
                <TextInput
                  autoComplete='email'
                  name='email'
                  errorText={
                    Boolean(getError(props, 'email')) && <TranslatedMessage {...getError(props, 'email')} />
                  }
                  labelText={<TranslatedMessage id='sign_in.form.placeholder_email' />}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email ?? ''}
                  wrapperClass={styles.textInput}
                />
                <TextInput
                  autoComplete='current-password'
                  name='password'
                  errorText={
                    Boolean(getError(props, 'password')) && <TranslatedMessage {...getError(props, 'password')} />
                  }
                  labelText={<TranslatedMessage id='sign_in.form.placeholder_password' />}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  icon='eye_outlined'
                  iconAriaLabel='show-password'
                  onIconClick={handleIconClick}
                  type={showPassword ? 'text' : 'password'}
                  value={values.password ?? ''}
                  wrapperClass={styles.textInput}
                />

                <LocalizedLink
                  className={styles.forgotPasswordLink}
                  href={paths.resetPassword}
                >
                  <TranslatedMessage id='sign_in.form.forgot_password_updated' />
                </LocalizedLink>

                {!isFromOauth && (' | ')}
                {!isFromOauth && (
                  <LocalizedLink
                    className={styles.forgotPasswordLink}
                    href={paths.accountRecovery}
                  >
                    <TranslatedMessage id='sign_in.form.recover_account' />
                  </LocalizedLink>
                )}

                {showStatesSelector && (
                  <>
                    <StateSelector
                      isCanada={code === 'juul-ca'}
                      selector={selector}
                      selectedState={values.state}
                      callback={async val => {
                        await props.setFieldValue('state', val)
                        await props.setFieldTouched('state.value')
                      }}
                    />
                    {getError(props, 'state.value') && (
                      <div className={styles.stateError}>
                        <TranslatedMessage {...getError(props, 'state.value')} />
                      </div>
                    )}
                    { blockedStates.includes(values.state.value) && (
                      <BlockedStateWarningMessage
                        stateLabel={values.state.label}
                      />
                    )}
                    <StateSelectorGeolocation />
                  </>
                )}

                <SignInConsent />

                <Button type='submit'
                  disabled={!isValid || !dirty}
                  processing={isSubmitting}
                >
                  <TranslatedMessage id='sign_in.form.submit_button' />
                </Button>
              </FormikForm>
            </>
          )
        }
      }
    </Formik>
  )
}
