import React, { memo, useState } from 'react'

import { viewlioConfig } from '@viewlio/config/src/viewlioConfig'
import { Media } from '@viewlio/types/src'
import cx from 'classnames'
import LazyLoad from 'react-lazyload'
import loadingStyles from 'styles/common/Loading.module.scss'

import { useMediaQuery } from 'utils/complianceUI'
import { getAspectRatio } from 'utils/image'

import { createUrl } from '.'
import {
  INFINITE_HEIGHT,
  MAX_CONTENTFUL_WIDTH,
  MIN_IMAGE_WIDTH,
  QUALITY_SETTING,
} from './constants'
import { ImageSources } from './ImageSources'
import styles from './NextGenImage.module.scss'

type AspectRatios = {
  desktop: number
  mobile: number
}

type Props = {
  altText?: string
  aspectRatio?: AspectRatios
  calcAspectRatio?: boolean
  desktopImage: Media
  imageClass?: string
  lazyLoad?: boolean
  maxHeights?: {
    desktop?: number
    mobile?: number
  }
  maxWidths: {
    desktop: number
    mobile: number
  }
  mobileImage: Media
  wrapperClass?: string
}

export const NextGenImage: React.FC<Props> = memo(({
  aspectRatio: customAspectRatio,
  altText,
  calcAspectRatio = false,
  desktopImage,
  imageClass,
  lazyLoad = true,
  maxHeights = null,
  maxWidths: _maxWidths,
  mobileImage,
  wrapperClass,
}) => {
  // Needed so that we can safely mutate
  const maxWidths = { ..._maxWidths }
  const [imageLoaded, setImageLoaded] = useState(false)

  const aspectRatio = calcAspectRatio ? {
    desktop: getAspectRatio(desktopImage),
    mobile: getAspectRatio(mobileImage),
  } : customAspectRatio

  const pictureClass = cx(wrapperClass, {
    [styles.imageWithRatio]: Boolean(aspectRatio),
    [loadingStyles.loadingGradient]: lazyLoad && !imageLoaded,
  })

  if (maxWidths.desktop * QUALITY_SETTING > MAX_CONTENTFUL_WIDTH) {
    maxWidths.desktop = Math.ceil(MAX_CONTENTFUL_WIDTH / QUALITY_SETTING)
  }

  const isDesktop = useMediaQuery(`(min-width: ${maxWidths.mobile + 1}px)`)
  const viewport = isDesktop ? 'desktop' : 'mobile'

  const pictureContent = (
    <>
      <ImageSources
        image={desktopImage}
        isDesktop={true}
        maxWidth={maxWidths.desktop}
        minWidth={maxWidths.mobile + 1}
      />
      <ImageSources
        image={mobileImage}
        isDesktop={false}
        maxWidth={maxWidths.mobile}
        minWidth={MIN_IMAGE_WIDTH}
      />

      {/* Img fallback */}
      <img
        alt={altText}
        onLoad={() => setImageLoaded(true)}
        className={imageClass}
        src={createUrl(mobileImage, maxWidths.mobile)}
        style={{
          maxHeight: `${maxHeights?.[viewport] ?? INFINITE_HEIGHT}px`,
        }}
      />
    </>
  )

  const placeholderPadding = Math.min(
    aspectRatio?.[viewport],
    maxHeights?.[viewport] ?? INFINITE_HEIGHT,
  )

  const pictureStyle = {
    paddingBottom: `${placeholderPadding * 100}%`,
  }

  return (
    <picture
      data-testid='next-gen-image'
      className={pictureClass}
      style={pictureStyle}
    >
      {
        lazyLoad ? (
          <LazyLoad
            debounce={5}
            offset={viewlioConfig.lazyLoadOffset}
            placeholder={
              <div className={styles.imagePlaceholder} />
            }
          >
            {pictureContent}
          </LazyLoad>
        ) : (
          <>
            {pictureContent}
          </>
        )}
    </picture>
  )
})

NextGenImage.displayName = 'NextGenImage'
