import React from 'react'

import { Checklist } from '@juullabs/react-components'
import { FormikValues, useFormikContext } from 'formik'

import { TranslatedMessage } from 'components/common/TranslatedMessage'
import { TranslatedMessageWithHtml } from 'components/common/TranslatedMessageWithHtml'
import { useGlobalStore } from 'stores'
import { getTranslatedError } from 'utils/formik/getTranslatedError'
import { withStoreCustomization } from 'utils/hocs/withStoreCustomization'
import { useFromOauthCookie } from 'utils/hooks'

import styles from './SignUpForm.module.scss'

const getTouched = (touched, name) => touched[name]

const getCheckboxControls = () => {
  const { juulioStore: {
    tosUrl,
    privacyPolicyUrl,
  } } = useGlobalStore()

  const { isFromOauth } = useFromOauthCookie()

  const {
    values,
    handleChange,
    handleBlur,
    setValues,
    touched,
    getFieldMeta,
  } = useFormikContext<FormikValues>()

  return {
    burdensomeClauses: {
      error: getTranslatedError(getFieldMeta('burdensomeClauses')),
      input: {
        checked: values.burdensomeClauses,
        name: 'burdensomeClauses',
        onBlur: handleBlur,
        onChange: handleChange,
      },
      label: (
        <TranslatedMessageWithHtml
          id='sign_in.login.burdensome_clauses'
          withTargetBlankAnchors={isFromOauth}
        />
      ),
      touched: Boolean(getTouched(touched, 'burdensomeClauses')),
    },
    newsletter: {
      error: getTranslatedError(getFieldMeta('shouldRenderNewsletter')),
      input: {
        checked: values.juulNewsletter,
        name: 'juulNewsletter',
        onBlur: handleBlur,
        onChange: handleChange,
      },
      label: (
        <TranslatedMessageWithHtml
          id='sign_up.form.newsletter_optin'
          values={{
            privacy_url: privacyPolicyUrl,
          }}
          withTargetBlankAnchors={isFromOauth}
        />
      ),
      touched: Boolean(getTouched(touched, 'juulNewsletter')),
    },
    selectAll: {
      error: null,
      input: {
        checked: Boolean(values.selectAll),
        name: 'selectAll',
        onBlur: handleBlur,
        onChange: (e) => {
          setValues({
            ...values,
            burdensomeClauses: e.target.checked,
            juulNewsletter: e.target.checked,
            selectAll: e.target.checked,
            termsAndConditions: e.target.checked,
          })
        },
      },
      label: <TranslatedMessage id='sign_up.form.select_all' />,
      touched: Boolean(getTouched(touched, 'selectAll')),
    },
    termsAndConditions: {
      error: getTranslatedError(getFieldMeta('termsAndConditions')),
      input: {
        checked: values.termsAndConditions,
        name: 'termsAndConditions',
        onBlur: handleBlur,
        onChange: handleChange,
      },
      label: (
        <TranslatedMessageWithHtml
          id='sign_up.form.by_registering_with'
          values={{
            privacy_url: privacyPolicyUrl,
            tos_url: tosUrl,
          }}
          withTargetBlankAnchors={isFromOauth}
        />
      ),
      touched: Boolean(getTouched(touched, 'termsAndConditions')),
    },
  }
}

const shouldRenderNewsletter = () => {
  const { juulioStore: {
    storeNewsletter: {
      isMarketingAllowed,
      isOptinRequired,
    },
  } } = useGlobalStore()

  return isMarketingAllowed && isOptinRequired
}

const DefaultCheckboxes: React.FC = () => {
  const checkboxes = [
    getCheckboxControls().termsAndConditions,
    shouldRenderNewsletter() && getCheckboxControls().newsletter,
  ].filter(Boolean)

  return (
    <Checklist
      a11yLegend={<TranslatedMessage id='sign_up.checkboxes_a11y_legend' />}
      checkboxes={checkboxes}
      className={styles.checklist} />
  )
}

const ItalyCheckboxes: React.FC = () => {
  const checkboxes = [
    getCheckboxControls().selectAll,
    getCheckboxControls().termsAndConditions,
    getCheckboxControls().burdensomeClauses,
    shouldRenderNewsletter() && getCheckboxControls().newsletter,
  ].filter(Boolean)

  return (
    <Checklist
      a11yLegend={<TranslatedMessage id='sign_up.checkboxes_a11y_legend' />}
      checkboxes={checkboxes}
      className={styles.checklist} />
  )
}

export const SignUpFormCheckboxes =
  withStoreCustomization({ 'juul-it': ItalyCheckboxes }, DefaultCheckboxes)
