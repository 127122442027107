import { viewlioConfig } from '@viewlio/config/src/viewlioConfig'
import { Media } from '@viewlio/types/src'

import { addParams } from 'utils/urls/addParams'

import { MAX_CONTENTFUL_WIDTH } from './constants'

const { imageParamMap } = viewlioConfig

export const createUrl = (
  image: Media,
  width: number,
  contentType?: string,
) => {
  contentType = contentType || image.contentType
  if (width > MAX_CONTENTFUL_WIDTH) {
    width = MAX_CONTENTFUL_WIDTH
  }
  return addParams(image.url, {
    ...imageParamMap[contentType],
    w: Math.floor(width).toString(),
  })
}
