import React, { FC } from 'react'

import { InlineNotification } from '@juullabs/react-components'

import { TranslatedMessage } from 'components/common/TranslatedMessage'

type Props = {
  stateLabel: string
}

export const BlockedStateWarningMessage: FC<Props> =
  ({ stateLabel }) => {
    const text = (
      <TranslatedMessage
        id='sign_in.form.blocked_state_warning_fullscreen'
        values={{ state: stateLabel }}
      />
    )

    return (
      <InlineNotification content={text} type='warning'/>
    )
  }
