export enum CreditCard {
  AMEX = 'American Express',
  BANCONTACT = 'Bancontact',
  CARTES_BANCAIRES = 'Cartes Bancaires',
  DINERS_CLUB = 'Diners Club',
  DISCOVER = 'Discover',
  JCB = 'JCB',
  MAESTRO = 'Maestro',
  MASTERCARD = 'MasterCard',
  PAYPAL = 'PayPal',
  UNIONPAY = 'UnionPay',
  VISA = 'Visa'
}
