import {
  useIntl,
} from 'react-intl'

import { getMessageId } from './getMessageId'

export const useTranslations = () => {
  const intl = useIntl()

  const translateMessage = (
    id: string,
    values?: Record<string, any>,
  ) => intl.formatMessage({ id: getMessageId(id, values, intl) }, values)

  return {
    translateMessage,
  }
}
